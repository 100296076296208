import React from 'react'

const _Home = () => {
  return (
    <div className="h-100 bgBlack">
        
    </div>
  )
}

export default _Home